import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import {
    getSessionDataFromAccessResponse,
    impersonateUser,
    signOut,
} from "components/login/login.api";
import { useCookies } from "react-cookie";
import {
    ADMIN_SESSION_KEY,
    defaultCookieOptions,
    SESSION_KEY,
} from "./constants";
import { useGetAdminSession } from "hooks/useGetUserSession";

export const LoginAsButton = ({ userId, ...btnProps }) => {
    const navigate = useNavigate();
    const adminAppSession = useGetAdminSession();
    const [, setCookies] = useCookies([SESSION_KEY]);

    const loginAsUser = () => {
        impersonateUser(userId).then(({ success, result: accessToken }) => {
            if (!success) {
                signOut(() => navigate("/login"));
            } else {
                const data = getSessionDataFromAccessResponse(accessToken);
                setCookies(SESSION_KEY, data, {
                    ...defaultCookieOptions,
                });
                setCookies(ADMIN_SESSION_KEY, adminAppSession, {
                    ...defaultCookieOptions,
                });

                window.location =
                    process.env.REACT_APP_BP_BASE_URL;
            }
        });
    };

    return (
        <Button {...btnProps} onClick={loginAsUser}>
            Login
        </Button>
    );
};
